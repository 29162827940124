function App() {
  return (
    <div className="App" >
      <div style={{display: 'flex',flexDirection:'column', justifyContent:'center', alignItems:'center', height: '90vh'}}>
      <h1 class='logo-title'  style={{fontSize:'60px',margin:0, padding:0}}>LE LORD</h1>
      <p style={{margin:0, padding: 0, fontSize:'0.8rem', letterSpacing:'1px', color: '#444444'}}>Website undergoing enhancements.</p>
      </div>
       
    </div>
  );
}

export default App;
